var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sessionTimeCard",style:(_vm.$vuetify.breakpoint.xs ? 'width: 100%;' : 'width: 100%;')},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-progress-circular',{staticStyle:{"margin-right":"12px"},attrs:{"size":"24","value":_vm.progressCircularValue,"color":"#144FA9"}}),_c('span',{staticStyle:{"font-weight":"400","font-size":"12px","line-height":"14px","letter-spacing":"0.1em","color":"#000000"}},[_vm._v(_vm._s(_vm._f("localize")('session_expire_label'))+" "),(
          _vm.minutes == 0 ||
          _vm.minutes == 1 ||
          _vm.minutes == 2 ||
          _vm.minutes == 3 ||
          _vm.minutes == 4 ||
          _vm.minutes == 5 ||
          _vm.minutes == 6 ||
          _vm.minutes == 7 ||
          _vm.minutes == 8 ||
          _vm.minutes == 9
        )?_c('span',[_vm._v("0")]):_vm._e(),_vm._v(_vm._s(_vm.minutes)+":"),(
          _vm.seconds == 0 ||
          _vm.seconds == 1 ||
          _vm.seconds == 2 ||
          _vm.seconds == 3 ||
          _vm.seconds == 4 ||
          _vm.seconds == 5 ||
          _vm.seconds == 6 ||
          _vm.seconds == 7 ||
          _vm.seconds == 8 ||
          _vm.seconds == 9
        )?_c('span',[_vm._v("0")]):_vm._e(),_vm._v(_vm._s(_vm.seconds)+" хв.")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }