var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticStyle:{"z-index":"100","min-height":"100svh"},attrs:{"fixed":"","right":"","width":"400px","height":"max-content","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{key:_vm.schemeKey,staticStyle:{"width":"100%","overflow-x":"auto","overflow-y":"hidden","display":"flex","flex-direction":"row","align-items":"flex-start","justify-content":"center","padding":"20px"}},_vm._l((_vm.new_seats_scheme),function(row,rowIndex){return _c('div',{key:rowIndex,staticStyle:{"display":"flex","flex-direction":"column","margin-right":"10px"}},_vm._l((row),function(seat,seatIndex){return _c('div',{key:seatIndex,staticClass:"seat",staticStyle:{"cursor":"pointer","user-select":"none","position":"relative"},on:{"click":function($event){seat.for_sell ? _vm.chooseSeat(seat.number, rowIndex, seatIndex) : ''}}},[(seat.type === 'seats')?_c('span',{staticStyle:{"position":"absolute","color":"white","z-index":"100","top":"50%","left":"50%","transform":"translate(-50%, -50%)"}},[_vm._v(" "+_vm._s(seat?.number)+" ")]):_vm._e(),(seat.type !== 'aisle' && seat.type !== 'seats')?_c('img',{staticStyle:{"transform":"rotate(-90deg)"},attrs:{"width":"42px","height":"42px","src":_vm.getSeatImage(seat?.type),"alt":seat.type}}):(seat.type === 'seats')?_c('div',{staticClass:"seatsIcon",staticStyle:{"width":"42px","height":"42px","transform":"rotate(-90deg)"},style:(seat.status === 'Sold'
              ? 'background: #6B7C8A; pointer-events: none;'
              : seat.status === 'Reserved' || seat.status === 'Booked'
              ? 'background: #FBBB55'
              : seat.for_sell
              ? 'background: #149E51'
              : 'background: #6B7C8A; pointer-events: none;')}):_vm._e()])}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }