<template>
  <div class="infoBanner">
    <v-row no-gutters align="start">
      <img src="@/assets/img/iconsSvg/infoIcon.svg" alt="info icon" />
      <v-col class="py-0" style="padding-left: 12px">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.infoBanner {
  border-radius: 10px;
  border: 1px solid #144fa9;
  background: #f6f8fc;
  padding: 16px;
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>