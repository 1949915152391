<template>
  <div
    class="sessionTimeCard"
    :style="$vuetify.breakpoint.xs ? 'width: 100%;' : 'width: 100%;'"
  >
    <v-row no-gutters align="center" justify="center">
      <v-progress-circular
        size="24"
        :value="progressCircularValue"
        color="#144FA9"
        style="margin-right: 12px"
      />
      <span
        style="
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.1em;
          color: #000000;
        "
        >{{ 'session_expire_label' | localize }}
        <span
          v-if="
            minutes == 0 ||
            minutes == 1 ||
            minutes == 2 ||
            minutes == 3 ||
            minutes == 4 ||
            minutes == 5 ||
            minutes == 6 ||
            minutes == 7 ||
            minutes == 8 ||
            minutes == 9
          "
          >0</span
        >{{ minutes }}:<span
          v-if="
            seconds == 0 ||
            seconds == 1 ||
            seconds == 2 ||
            seconds == 3 ||
            seconds == 4 ||
            seconds == 5 ||
            seconds == 6 ||
            seconds == 7 ||
            seconds == 8 ||
            seconds == 9
          "
          >0</span
        >{{ seconds }} хв.</span
      >
    </v-row>
    <!-- <bookingTimeExpired
      v-if="showTimeExpiredModal"
      :visible="showTimeExpiredModal"
    /> -->
  </div>
</template>

<script>
//import bookingTimeExpired from "@/components/UI/modals/bookingTimeExpired.vue";
export default {
  components: {
    //bookingTimeExpired,
  },
  data: () => ({
    minutes: 15,
    startDate: "",
    seconds: 0,
    progressCircularValue: 0,
    showTimeExpiredModal: false,
    time: localStorage.getItem("startDate"),
  }),
  mounted() {
    if (localStorage.getItem("startDate")) {
      let diff_time =
        Math.abs(new Date() - new Date(localStorage.getItem("startDate"))) /
        1000;
      this.minutes = 14 - Math.floor(diff_time / 60);
      this.seconds =
        60 - Math.floor(diff_time - Math.floor(diff_time / 60) * 60);
      if (this.minutes <= 0 || this.minutes > 15) {
        this.minutes = 15;
        this.startDate = new Date();
      }
    } else {
      this.startDate = new Date();
    }
    this.setProgressCircular();
    setInterval(this.setTime, 1000);
  },
  methods: {
    setTime() {
      if (this.seconds == 0) {
        if (this.minutes !== 0) {
          this.minutes--;
          this.seconds = 60;
        } else {
          this.showTimeExpiredModal = true;
          this.$emit("timeExpired");
        }
      } else {
        this.seconds--;
      }
    },
    setProgressCircular() {
      this.progressCircularValue = ((15 - this.minutes) * 60) / 9;
      setInterval(() => {
        this.progressCircularValue++;
      }, 9000);
    },
  },
  destroyed() {
    if (this.startDate) {
      localStorage.setItem("startDate", this.startDate);
    }
  },
};
</script>

<style>
.sessionTimeCard {
  border-radius: 10px;
  background: #e7edf6;
  box-shadow: 1px 1px 4px 0px rgba(27, 27, 27, 0.15);
  padding: 16px;
  color: #4B5262;
font-family: "MacPaw Fixel";
font-size: 16px;
font-style: normal;
font-weight: 600;
width: 100%;
}
</style>