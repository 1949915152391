<template>
  <booking-ticket-component />
</template>

<script>
import bookingTicketComponent from '../../components/User/bookingTicket/bookingTicketComponent.vue'
export default {
  components: { bookingTicketComponent },

}
</script>

<style>

</style>