<template>
  <v-col class="px-0 py-0 text">
    <v-row no-gutters style="margin: 0px; padding: 0px">
      <div style="width: 40px">
        <span class="time">{{
          new Date(
            addMinutesToDate(
              detailOrder?.schedule?.departed_at,
              detailOrder?.schedule?.route?.cities?.find(
                (city) => city.station.city_uuid == detailOrder?.departure.id
              )?.time_from_start
            )
          ).toLocaleTimeString("uk-UA", {
            hour: "2-digit",
            minute: "2-digit",
          })
        }}</span>
      </div>
      <div style="width: 20px; margin: 0px 8px">
        <v-col class="px-0 py-0">
          <div
            style="
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: 2px solid #144fa9;
            "
          ></div>
          <div
            style="
              width: 0px;
              border: 1.5px dashed #144fa9;
              margin: 2px 0px;
              margin-left: 8px;
            "
            :style="!isDetailRoute ? 'height: 70px' : 'height: 28px;'"
          />
        </v-col>
      </div>
      <v-col class="px-0 py-0">
        <span
          >{{
            detailOrder?.schedule?.route?.cities?.find(
              (city) => city?.station?.city_uuid == detailOrder.departure.id
            )?.station?.translations?.name
          }},
          {{
            detailOrder?.schedule?.route?.cities?.find(
              (city) => city?.station?.city_uuid == detailOrder.departure.id
            )?.station?.translations?.address
          }}</span
        >
        <v-row no-gutters justify="center">
          <button
            class="detailBtn"
            v-if="!isDetailRoute"
            @click="isDetailRoute = true"
          >
            <v-icon style="margin-right: 4px">mdi-chevron-up</v-icon>
            <v-icon>mdi-chevron-down</v-icon>
          </button>
        </v-row>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-col class="px-0 py-0" v-if="isDetailRoute">
        <v-row
          no-gutters
          v-for="city in detailOrder?.schedule?.route?.cities"
          :key="city.id"
          style="margin: 0px"
          v-show="
            city?.station?.city_uuid !== detailOrder.departure.id &&
            city?.station?.city_uuid !== detailOrder.destination.id
          "
        >
          <div style="width: 40px; padding-top: 3px;">
            <span class="time">{{ city?.arrival }}</span>
          </div>
          <div style="width: 20px; margin: 0px 8px 0px 6px">
            <v-col class="px-0 py-0">
              <img
                src="@/assets/img/iconsSvg/mapMarker.svg"
                alt="map marker"
                style="margin-left: 1px"
              />
              <div
                style="
                  width: 0px;
                  height: 28px;
                  border: 1.5px dashed #144fa9;
                  margin: 0px 0px 3px 0px;
                  margin-left: 10px;
                "
              />
            </v-col>
          </div>
          <v-col class="px-0 py-0">
            <span
              >{{ city?.station?.translations?.name }},
              {{ city?.station?.translations?.address }}</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-expand-transition>
    <v-row no-gutters align="start" style="margin: 0px">
      <div
        style="width: 39px"
        :style="!isDetailRoute ? 'margin-top: 16px' : ''"
      >
        <span class="time">{{
          new Date(
            addMinutesToDate(
              detailOrder?.schedule?.departed_at,
              detailOrder?.schedule?.route?.cities?.find(
                (city) => city.station.city_uuid == detailOrder?.destination.id
              )?.time_from_start
            )
          ).toLocaleTimeString("uk-UA", {
            hour: "2-digit",
            minute: "2-digit",
          })
        }}</span>
      </div>
      <div style="width: 20px; margin: 0px 8px">
        <v-col class="px-0 py-0">
          <div
            v-if="!isDetailRoute"
            style="
              width: 0px;
              height: 12px;
              border: 1.5px dashed #144fa9;
              margin: 0px 0px;
              margin-left: 9px;
            "
          />
          <div
            style="
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: 2px solid #144fa9;
              padding: 2px;
              display: grid;
              place-items: center;
              margin-left: 1px;
            "
          >
            <div
              style="
                width: 10px;
                height: 10px;
                background: #144fa9;
                border-radius: 50%;
              "
            ></div>
          </div>
        </v-col>
      </div>
      <v-col
        class="px-0 py-0"
        :style="!isDetailRoute ? 'margin-top: 17px' : ''"
      >
        <span
          >{{
            detailOrder?.schedule?.route?.cities?.find(
              (city) => city?.station?.city_uuid == detailOrder.destination.id
            )?.station?.translations?.name
          }},
          {{
            detailOrder?.schedule?.route?.cities?.find(
              (city) => city?.station?.city_uuid == detailOrder.destination.id
            )?.station?.translations?.address
          }}</span
        >
      </v-col>
    </v-row>
    <button
      class="detailBtn"
      style="width: 100%"
      @click="isDetailRoute = false"
      v-if="isDetailRoute"
    >
      <v-icon style="margin-right: 12px">mdi-chevron-up</v-icon>
      <span> {{ "hide_btn" | localize }} </span>
    </button>
  </v-col>
</template>
  
  <script>
import diffrentTimeMixin from "../../../mixins/diffrentTimeMixin";
export default {
  mixins: [diffrentTimeMixin],
  data: () => ({
    isDetailRoute: false,
  }),
  props: {
    detailOrder: {
      require: true,
    },
  },
};
</script>
  
  <style scoped>
.text {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.time {
  font-weight: 600;
}
.detailBtn {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 4px 8px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 0px;
}
</style>