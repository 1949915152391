<template>
  <v-dialog v-model="visibility" width="380px" :persistent="persistent">
    <div class="modalBackground">
      <img src="@/assets/img/iconsSvg/errorIcon.svg" alt="error icon" />
      <p style="margin-top: 24px" class="modalTitle">{{ title }}</p>
      <p class="modalText" v-if="text" v-html="text"></p>
      <v-col class="px-0 py-0">
        <submit-button
          v-if="isSearchTripBtn"
          @click="$emit('searchTrip')"
          :width="'100%'"
          style="height: 48px !important"
          >Пошук рейсів</submit-button
        >
        <cancel-btn v-if="cancelBtn" @click="$emit('close')" text="Закрити" />
      </v-col>
    </div>
  </v-dialog>
</template>
  
  <script>
import modalMixin from "@/mixins/modalMixin";
import SubmitButton from "./Buttons/submitButton.vue";
import CancelBtn from "./Buttons/cancelBtn.vue";
export default {
  components: { SubmitButton, CancelBtn },
  mixins: [modalMixin],
  props: {
    title: {
      require: true,
    },
    text: {
      require: false,
    },
    searchBtn: {
      require: false,
    },
    cancelBtn: {
      require: false,
    },
    persistent: {
      require: false,
    },
    isSearchTripBtn: {
      require: false,
    },
  },
};
</script>
  
  <style scoped>
.modalBackground {
  background: #fcfcfc;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: max-content;
  padding: 32px;
  text-align: center;
}
.modalTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #1b1b1b;
}
.modalText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #1b1b1b;
}
</style>